import React from 'react';
import styles from './SimplifiedPriceOffer.module.css';

const SimplifiedPriceOffer = ({price, duration, index}) => {
    return (
        <div className={styles.priceOffer} data-aos='fade-down' data-aos-delay={150 * index}>
                <div className={styles.priceContainer}>
                    <div className={styles.price} >{price}</div>
                    <div className={styles.duration}> / {duration}</div>
                </div>
        </div>
    );
};

export default SimplifiedPriceOffer;
import React, {useContext} from 'react';
import styles from './PricingBlock.module.css';
import Title from '../title/Title';
import Text from '../text/Text';
import SimplifiedPriceOffer from '../simplified-price-offer/SimplifiedPriceOffer';
import Button from '../button/Button';
import ContactModalFormContext from '../../context/ContactModalFormContext';

const PricingBlock = () => {
    const showContactForm = useContext(ContactModalFormContext);
    const priceOffers = [
        {
            name: '«Расслабляющий массаж»',
            price: '₽3000',
            duration: 'час',
            features: [

            ]
        },
        {
            name: '«Ритуал огня»',
            price: '₽5000',
            duration: 'полтора',
            features: [

            ]
        }
    ];
    return (
        <div className={styles.pricingBlock}>

            <Title className={styles.hideMobile}>Подарочные сертификаты</Title>
            <div className={styles.desktopFlexBlock}>
                <Text className={styles.hideDesktop}>Кстати, массажную сессию тотального расслабления можно подарить.</Text>
                <img className={styles.certificate} src={`${process.env.PUBLIC_URL}/assets/certificate.avif`} data-aos="fade-up" alt='Сертификат' width='320' height='568'/>
                <div className={styles.priceAndCaptions}>
                    <Text style={{marginTop: 0}} className={styles.hideMobile}>Кстати, массажную сессию тотального расслабления можно подарить.</Text>
                    <Text>
                        Мне кажется, это лучшее проявление заботы и&nbsp;любви к&nbsp;тем, кто&nbsp;вам&nbsp;дорог!
                    </Text>
                    {priceOffers.map((o, i) => <SimplifiedPriceOffer key={i} name={o.name} price={o.price} duration={o.duration} features={o.features} index={i}/>)}

                    <Button additionalClassName={styles.certificateButton} color='purple' filled={true} dataAos='zoom-in' onClick={() => showContactForm(`Хочу заказать сертификат`)}>Заказать сертификат</Button>
                </div>
            </div>
        </div>
    );
};

export default PricingBlock;
import React, {useState, useEffect, useContext} from 'react';
import styles from './Navigation.module.css';
import {ReactComponent as Logo} from '../../assets/images/fire-filled-icon.svg';
import {ReactComponent as DialogIcon} from '../../assets/images/dialog-icon.svg';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import {useScrollTop} from '../../hooks/useScrollTop';
import BurgerButton from '../burger-button/BurgerButton';
import ContactModalFormContext from '../../context/ContactModalFormContext';
import ScrollLink from '../scroll-link/ScrollLink';
import SocialLinks from '../social-links/SocialLinks';

const Navigation = ({navLinks}) => {
    const [mobileExpanded, setMobileExpanded] = useState(false);
    const scrollTop = useScrollTop();
    const showContactForm = useContext(ContactModalFormContext);

    useEffect(() => {
        if(mobileExpanded){
            disableBodyScroll(document.getElementsByName('body'));
        }else{
            enableBodyScroll(document.getElementsByName('body'));
        }
    }, [mobileExpanded]);

    const toggleContactForm = () => {
        setMobileExpanded(false);
        showContactForm();
    };

    return (
        <div className={`${styles.navigation} ${scrollTop > 50 ? styles.scrolled : ''} ${mobileExpanded ? styles.mobileExpanded : ''}`}>
            <div className={styles.mobilePanel}>
                <ScrollLink onClick={()=> setMobileExpanded(false)} className={styles.logo} to="#" data-aos="fade-right" aria-label='Главная страница'>
                    <div className={styles.logoImageWrapper}>
                        <Logo/>
                    </div>
                    <div className={styles.logoText}></div>
                </ScrollLink>
                <BurgerButton isExpanded={mobileExpanded} isPageScrolled={scrollTop > 50} onClick={()=>setMobileExpanded(!mobileExpanded)}/>
            </div>
            <div className={styles.actionsWrapper}>
                <div className={styles.linksContainer} >
                    {navLinks.map((l, i) => <ScrollLink onClick={()=> setMobileExpanded(false)} key={i} className={styles.navLink} to={l.href}><span className={styles.linkTitle}>{l.name}</span></ScrollLink>)}
                </div>
                <div className={styles.contactWrapper}>
                    <div className={styles.contactButton} onClick={toggleContactForm} >
                        <div className={styles.hoverBg}/>
                        <DialogIcon/>
                        <div className={styles.contactText}>Связаться</div>
                    </div>
                </div>
                <div className={styles.socialLinksWrapper}>
                    <SocialLinks className={styles.socialLinksContainer}/>
                </div>
            </div>
        </div>
    );
};

export default Navigation;
import React from 'react';
import styles from './PromoBlockItem.module.css';

const PromoBlockItem = ({children, ...rest}) => {
    return (
        <div className={styles.promoBlockItem} {...rest}>
            {children}
        </div>
    );
};

export default PromoBlockItem;
import React from 'react';
import styles from './Footer.module.css';
import {ReactComponent as Logo} from '../../assets/images/fire-filled-icon.svg';
import ContactForm from '../contact-form/ContactForm';
import ScrollLink from '../scroll-link/ScrollLink';
import SocialLinks from '../social-links/SocialLinks';

const Footer = ({navLinks}) => {
    const devYear = 2023;
    const currentYear = (new Date()).getFullYear();
    return (
        <div className={styles.footerWrapper}>
            <div className={styles.footerContent}>
                <div className={styles.column}>
                    <ScrollLink className={styles.logo} to="#" aria-label='В начало'>
                        <div className={styles.logoImageWrapper} data-aos="fade-up">
                            <Logo/>
                        </div>
                    </ScrollLink>
                    <div className={styles.motoText}>{`Тотальное расслабление ${currentYear > devYear ? currentYear : devYear}`}</div>
                </div>
                <div className={styles.columnGroup}>
                    <div className={styles.column} data-aos="fade-up">
                        <div className={styles.columnTitle} data-aos="fade-up">Навигация</div>
                        <div className={styles.linksContainer}>
                            {navLinks.map((l, i) =>  <ScrollLink key={i} className={styles.footerLink} to={l.href}>{l.name}</ScrollLink>)}
                        </div>
                    </div>
                    <div className={styles.column} data-aos="fade-up">
                        <div className={styles.columnTitle} data-aos="fade-up">Контакты</div>
                        <SocialLinks/>
                    </div>
                    <div className={`${styles.column} ${styles.formWrapper}`} data-aos="fade-up">
                        <div className={styles.columnTitle}>Записаться или задать вопрос</div>
                        <ContactForm/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
import React, {useContext} from 'react';
import PromoBlockItem from '../promo-block-item/PromoBlockItem';
import Title from '../title/Title';
import Button from '../button/Button';
import Text from '../text/Text';
import ContactModalFormContext from '../../context/ContactModalFormContext';

const PromoBlock = () => {
    const showContactForm = useContext(ContactModalFormContext);
    return (
        <>
            <PromoBlockItem >
                <img src={`${process.env.PUBLIC_URL}/assets/massage-3.avif`} data-aos="fade-up" alt='Сессия массажа' width='640' height='850'/>
                <div>
                    <Title id='should-i-try'>Вам точно стоит это попробовать, если:</Title>

                        <div style={{display: 'block'}}>
                            <Text data-aos="fade-down" style={{marginBottom: '20px'}}> 1. Вы хотите развить контакт с&nbsp;телом и&nbsp;телесную чувствительность</Text>

                            <Text data-aos="fade-down" style={{margin: '20px 0'}}> 2. Вы чувствуете, что пришло время отдохнуть душой и&nbsp;телом</Text>

                            <Text data-aos="fade-down" style={{margin: '20px 0'}}>3. Вы чувствуете, что вы&nbsp;на&nbsp;пороге чего-то нового, и&nbsp;нужно завершить этот переход телесным процессом </Text>

                            <Text data-aos="fade-down" style={{margin: '20px 0'}}>4. Вы чувствуете, что устали и&nbsp;хотите восстановить силы</Text>

                            <Text data-aos="fade-down" style={{margin: '20px 0'}}>5. Вы чувствуете напряжение в&nbsp;теле</Text>

                            <Text data-aos="fade-down" style={{margin: '20px 0'}}>6. Вы хотите посмотреть на&nbsp;мир из&nbsp;другого состояния</Text>

                            <Text data-aos="fade-down" style={{margin: '20px 0'}}>7. Вы хотите проявить любовь к&nbsp;себе и&nbsp;своему телу</Text>

                            <Text data-aos="fade-down" style={{margin: '20px 0'}}>8. Вам кажется, что вы&nbsp;вообще не&nbsp;чувствуете своего тела</Text>

                            <Text data-aos="fade-down" style={{margin: '20px 0'}}>9. Вы хотите немного «отлететь»</Text>

                            <Text data-aos="fade-down" style={{marginTop: '20px'}}>10. Вам кажется, что Вы&nbsp;здесь не&nbsp;случайно&nbsp;:) </Text>
                        </div>

                    <Button color="purple" filled={true} dataAos='fade-up' onClick={() => showContactForm('', 'Записаться')}>Что-то такое чувствую...</Button>
                </div>
            </PromoBlockItem>
            <PromoBlockItem id='why-do-i-need-this'>
                <div>
                    <Title>А что в итоге…</Title>
                    <Text>
                        <span data-aos="fade-down">Сейчас тут будет небольшая лекция о&nbsp;том, почему контакт с&nbsp;телом важен в&nbsp;наше время и&nbsp;почему его надо развивать. </span>
                        <br/><br/>
                        <span data-aos="fade-down">Мы&nbsp;живем в&nbsp;век невероятного количества информации и&nbsp;информационного шума, в&nbsp;частности.</span>
                        <br/><br/>
                        <span data-aos="fade-down">Понять, чего мы хотим от&nbsp;жизни, умом очень сложно, намного проще это&nbsp;почувствовать. Почувствовать ощущениями в&nbsp;теле. Но для&nbsp;этого должен быть этот навык, навык телесной осознанности, навык телесной внимательности, навык телесной чувствительности, называйте как&nbsp;хотите. Собственно, за&nbsp;этим мы&nbsp;все&nbsp;здесь и&nbsp;собрались. Развивать этот навык, чувствовать и&nbsp;жить нашу лучшую жизнь!</span>
                        <br/><br/>
                        <span data-aos="fade-down">Очень советую к&nbsp;прочтению: <br/> <a style={{color: 'var(--color-purple)', textDecoration: 'none', fontWeight: 'bold', borderBottom: '1px dashed var(--color-purple)'}} target='_blank' rel='noreferrer' href='https://www.google.ru/books/edition/%D0%A2%D0%B5%D0%BB%D0%BE_%D0%BF%D0%BE%D0%BC%D0%BD%D0%B8%D1%82_%D0%B2%D1%81%D0%B5_%D0%9A%D0%B0%D0%BA%D1%83%D1%8E/bJTcDwAAQBAJ?hl=en&gbpv=1&dq=%D0%91%D0%B5%D1%81%D1%81%D0%B5%D0%BB%20%D0%B2%D0%B0%D0%BD%20%D0%B4%D0%B5%D1%80%20%D0%9A%D0%BE%D0%BB%D0%BA.%20%D0%A2%D0%B5%D0%BB%D0%BE%20%D0%BF%D0%BE%D0%BC%D0%BD%D0%B8%D1%82%20%D0%B2%D1%81%D0%B5&pg=PP1&printsec=frontcover'>Бессел ван дер Колк. Тело помнит все</a></span>
                    </Text>
                    <Button color="purple" filled={true} dataAos='fade-up' onClick={() => showContactForm('Хочу развить телесный контакт', '')}>Хочу развить телесный контакт</Button>
                </div>
            </PromoBlockItem>
        </>
    );
};

export default PromoBlock;
import React, {useContext} from 'react';
import styles from './Intro.module.css';
import {ReactComponent as BgClip} from '../../assets/images/intro-bg-clip.svg';
import Button from '../button/Button';
import ContactModalFormContext from '../../context/ContactModalFormContext';
const Intro = () => {
    const showContactForm = useContext(ContactModalFormContext);
    return (
        <div className={styles.wrapper} style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/massage-1.avif)`}}>
            <div className={styles.introContent}>
                <h2 className={styles.introTitle} data-aos="zoom-in">
                    Массажная сессия тотального расслабления
                </h2>
                {/*<h3 className={styles.introSubtitle} data-aos="fade-down">*/}
                {/*    с&nbsp;ритуалом огня, эфирными маслами и&nbsp;знающими руками*/}
                {/*</h3>*/}
                <p className={styles.introText} style={{marginTop: '0'}} data-aos="fade-down">
                    Телесный процесс, выполняющийся в&nbsp;мягком массажном стиле, с&nbsp;использованием тибетской техники массажа огнём и&nbsp;эфирных масел.
                </p>

                <div className={styles.buttonsContainer}>
                    <Button onClick={()=> showContactForm('', 'Записаться')} color="purple" filled={true} >Записаться</Button>
                </div>
                {/*<IntroBanner/>*/}
                {/*<LaptopSlider/>*/}
            </div>
            <BgClip className={styles.bgClip}/>
        </div>
    );
};

export default Intro;
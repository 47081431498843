import React from 'react';
import styles from './Text.module.css';

const Text = ({children, style, className}) => {
    return (
        <p className={`${styles.text} ${className}`} style={style} data-aos="fade-down">
            {children}
        </p>
    );
};

export default Text;
import React from 'react';
import styles from './BurgerButton.module.css'

const BurgerButton = ({isExpanded, isPageScrolled, onClick}) => {
    const color = !isPageScrolled && !isExpanded ? 'var(--color-white)' : 'var( --color-purple)';
    return (
        <button className={`${styles.button} ${isExpanded ? styles.isExpanded : ''}`} onClick={onClick} aria-label='Меню'>
            <div className={styles.panel} style={{backgroundColor: color}}></div>
            <div className={styles.panel} style={{backgroundColor: color}}></div>
            <div className={styles.panel} style={{backgroundColor: color}}></div>
        </button>
    );
};

export default BurgerButton;
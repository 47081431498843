import React from 'react';
import styles from './Title.module.css';

const Title = ({children, style, className, ...rest}) => {
    return (
        <h2 className={`${styles.title} ${className}`} style={style} data-aos="zoom-in" {...rest}>
            {children}
        </h2>
    );
};

export default Title;
import React from 'react';
import styles from './FormInput.module.css';
import {ReactComponent as CheckCircleIcon} from '../../assets/images/check-circle-icon.svg';
import {ReactComponent as CrossCircleIcon} from '../../assets/images/cross-circle-icon.svg';

const FormInput = ({value, type, placeholder, name, onChange, valid, touched, maxLength, inputMode}) => {
    let maskPhoneInput = (event) => {
        let x = event.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
        event.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '') + (x[4] ? '-' + x[4] : '');
    };
    return (
        <div className={`${styles.wrapper} ${!value.length ? styles.empty : ''}
                                           ${touched ? styles.touched : styles.untouched}
                                           ${valid ? styles.valid : styles.invalid}
                                           ${type === 'phone' ? styles.typePhone : ''}`}>
            {touched && !valid ? <CrossCircleIcon/> : <CheckCircleIcon/>}
            {type === 'phone' ? <span className={styles.countryCode}>+7</span> : ''}
            <span className={styles.placeholder}>{placeholder}</span>
            <input type={type} value={value} name={name} onChange={onChange} maxLength={maxLength} onInput={type === 'phone' ? maskPhoneInput : null} autoComplete='off' inputMode={inputMode} aria-label={name}/>
        </div>
    );
};

export default FormInput;
import React from 'react';
import styles from './SocialLinks.module.css';
import {ReactComponent as TelegramLogo} from '../../assets/images/telegram-logo.svg';
import {ReactComponent as InstagramLogo} from '../../assets/images/instagram-logo.svg';

const SocialLinks = ({className}) => {
    return (
        <div>
            <div className={`${styles.socialsContainer} ${className}`}>
                <a className={styles.socialLink} href='https://t.me/routinegirl' title='Telegram' target='_blank' rel='noreferrer' referrerPolicy='no-referrer'>
                    <TelegramLogo/>
                </a>
                <a className={styles.socialLink} href='https://instagram.com/@_routinegirl_' title='Instagram' target='_blank' rel='noreferrer' referrerPolicy='no-referrer'>
                    <InstagramLogo/>
                </a>
            </div>
        </div>
    );
};

export default SocialLinks;
import React from 'react';
import styles from './FeatureList.module.css';
import FeatureListItem from '../feature-list-item/FeatureListItem';
import MobileSlidableContent from '../mobile-slidable-content/MobileSlidableContent';

const FeatureList = () => {
    const features = [
        {
            title: 'Подготовка',
            subtitle: 'Процесс начинается с\u00A0оценки состояния тела (как я\u00A0сейчас? что чувствую в\u00A0теле?)',

        },
        {
            title: 'Нанесение смеси эфирных масел',
            subtitle: 'Эфирные масла моментально проникают в\u00A0кожу (после ритуала огня её\u00A0поры раскрыты) и\u00A0окутывают нас\u00A0—\u00A0и\u00A0вас, и\u00A0меня\u00A0тоже\u00A0😌\u00A0—\u00A0облаком приятных ароматов.',

        },
        {
            title: 'Ритуал огня',
            subtitle: 'Это уникальная, безопасная и\u00A0крайне приятная процедура. Я\u00A0использую ее в\u00A0начале, так как это помогает «уйти\u00A0в\u00A0тело» и\u00A0расслабиться уже с\u00A0первых минут процесса. Также процедура дает мне возможность намного глубже проработать все мышцы и\u00A0ткани, а\u00A0вам получить больше пользы и\u00A0приятных ощущений.',
        },
        {
            title: 'Массаж всего тела',
            subtitle: 'Или отдельных частей, по\u00A0вашему запросу и\u00A0желанию. Возможно, вам захочется уделить чуть больше внимания определенной части тела или\u00A0лицу,  решать вам\u00A0:)',

        },
        {
            title: 'Завершение процесса',
            subtitle: 'Часто после сессии бывает трудно быстро вернуться в\u00A0реальность и\u00A0бежать творить великие дела, поэтому я\u00A0помогаю мягко выйти из\u00A0процесса\u00A0с помощью техники landing (заземление).',

        },
    ];
    return (
        <div className={styles.wrapper}>
            <MobileSlidableContent>
                <div className={styles.featureList}>
                    {features.map((feature, i) => <FeatureListItem title={feature.title}
                                                                   subtitle={feature.subtitle}
                                                                   icon={feature.icon}
                                                                   key={i} index={i} total={features.length}
                                                                   button={feature.button}
                    />)}
                </div>
            </MobileSlidableContent>
        </div>
    );
};

export default FeatureList;
import React from 'react';
import styles from './FeatureListItem.module.css';

const FeatureListItem = ({title, subtitle, icon, index, total, button}) => {
    return <div className={styles.featureItem}  data-aos="fade-in" data-aos-delay={100 * index}>
        <div className={styles.iconWrapper} data-aos='fade-down' data-aos-delay={150 * index}>
            <div className={styles.iconBg}></div>
            <div className={styles.step}><span>{index + 1}</span></div>
        </div>
        <h3 className={styles.title} data-aos='fade-up' data-aos-delay={150 * index}>{title}</h3>
        <p className={styles.subtitle} data-aos="zoom-in" data-aos-delay={150 * index}>{subtitle}</p>
        {button ? button : ''}
    </div>;
};

export default FeatureListItem;